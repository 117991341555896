import EasyMDE from 'easymde'
import { marked } from 'marked'

document.addEventListener('DOMContentLoaded', () => {
    // Select all elements with the data-mde attribute
    const elements = document.querySelectorAll('[data-mde]')

    elements.forEach(element => {
        const isReadOnly = element.getAttribute('data-readonly') === 'true'

        if (isReadOnly) {
            // For read-only content, convert Markdown to HTML and display in a div
            let markdownContent = element.textContent || element.innerText // Extract Markdown content
            let htmlContent = marked.parse(markdownContent) // Convert Markdown to HTML

            element.innerHTML = htmlContent // Set HTML content
            element.style.whiteSpace = 'pre-wrap' // Preserve whitespace for Markdown formatting
        } else {
            // For editable content, initialize EasyMDE
            new EasyMDE({
                element: element
            })
        }
    })
})
